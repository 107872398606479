<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <!-- <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매량 모니터링 상품 추가 방법</u></p>
                        <div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                            <b-modal id="modal-3" size="xl" title="쿠팡 모니터링 상품 추가 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(판매량)" 버튼을 클릭해주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/모니터링추가.png')">
                            </b-modal> -->
					</div>
					<!-- <b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collecttr">{{linktext}}</b-button> -->
                </div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="collectenter($event)">
					<div class="d-flex justify-content-center align-items-center ml-4">
						<button type="button" class="btn btn-primary" @click="collect">키워드 분석</button>
						<div class="d-flex flex-column">
							<div class="custom-control custom-switch custom-control-inline ml-3">
								<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="related">
								<label class="custom-control-label" for="customSwitch1">연관검색어</label>
							</div>
							<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
								<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="exceptSameProductId">
								<label class="custom-control-label" for="customSwitch1">동일노출ID제외</label>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12 mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="col-lg-12" v-if="showlist">
				<b-button variant="outline-primary" class="mb-1 mr-2" @click="showts">
				트렌드세터
				</b-button>
				<b-button variant="outline-secondary" class="mb-1 mr-2" @click="showtf">
				트렌드팔로워
				</b-button>
			</div> -->

			<!-- <div class="col-lg-6 mt-3" v-if="showlist">
				<b-button variant="outline-success pl-5 pr-5" class="mt-2 mr-1 mb-2" v-b-toggle.my-collapse>판매량 보정</b-button>
				<b-collapse id="my-collapse">
					<b-card title="">
						보정치 적용 시 마이너스 판매량을 0으로 바꾸어 줍니다. (마이너스 임계치가 -2일경우 -2이하의 판매량은 모두 0으로 적용)
						<br>
						<br>
						[마이너스 임계치]
						<div class="form-group d-inline-block d-flex justify-content-between">
							<input type="number" class="form-control" placeholder="마이너스 임계치" v-model="minuslimit">

						</div>
						<div class="custom-control custom-switch custom-control-inline">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="applyminuslimit" @change="applycorrect">
								<label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom title="전체 페이지에서 검색하며, 여러개가 검색될 경우 페이지랭킹이 여러게로 나타납니다.">보정치 적용</label>
							</div>
					</b-card>
				</b-collapse>
			</div> -->

			<div class="col-lg-12 mt-3" v-if="showlist" style="font-size:80%">
                <CDataTable :headers="headers" :items="saleslists" v-model="tableSelectedItem" :show-select="true" itemKey="_id" :page="page" @changePage="changePage">
                    <template v-slot:header="{ props }">
                        <tr>
                            <th :colspan="props.headers.length">소싱방법 <br /> Badge</th>
                        </tr>
                    </template>
                    <template v-slot:로켓뱃지="{item}">
                        <div style="width:50px;overflow:hidden;text-overflow: ellipsis;">
                            <div>{{ item.type == 'trendSetter' ? 'Trend Setter' : 'Trend Follower' }}</div>
                            <span v-if="!item.로켓뱃지">없음</span><img v-if="item.로켓뱃지" :src="item.로켓뱃지" alt="image" style="max-width:100%">
                        </div>
                    </template>
                    <template v-slot:판매상품명="{item}">
                        <div style="min-width:250px;overflow:hidden;text-overflow: ellipsis; padding: 10px">
                            <div class="d-flex align-items-center">
                                <img :src="item.썸네일" class="img-fluid rounded avatar-100 mr-3" alt="image" @click="openWebsite(item.판매링크)">
                                <div>
                                    {{item.판매상품명}}
                                    <p class="mb-0"><small>ProductId: {{item.productId}}</small></p>
                                </div>
                            </div>
                        </div>
                    </template>
					<template v-slot:결제전환율="{item}">
                        {{(item.결제전환율*100).toFixed(2)}}%
                    </template>
					<template v-slot:조회수="{item}">
                        {{item.조회수 ? item.조회수.toLocaleString() : 0}}
                    </template>
                    <template v-slot:구매가="{item}">
                        <div class="d-flex flex-column mt-1 w-100">
                            <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                CNY <input type="number" class="form-control ml-1 text-center" placeholder="구매가(CNY)" v-model="item.구매가" @change="changeCost(item)">
                            </div>
                            <div class="d-flex justify-content-between align-items-center w-100 text-center">
                                &nbsp;&nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp; <input type="number" class="form-control ml-1 text-center" placeholder="매입가율" v-model="item.매입가율" @change="changeCost(item)">
                            </div>
                        </div>
                    </template>
                    <template v-slot:판매가="{item}">
                        <input type="number" class="form-control w-100 text-center" placeholder="판매가" v-model="item.판매가" @change="changeCost(item)">
                    </template>
                    <template v-slot:마진="{item}">
                        <td style="overflow:hidden;text-overflow: ellipsis;">{{item.마진.toLocaleString()}}<br>{{item.마진율 ? item.마진율.toFixed(1) + '%' : ''}}<br>{{item.ROI ? item.ROI.toFixed(1) + '%' : ''}}</td>
                    </template>
                    <template v-slot:수수료율="{item}">
                        {{item.수수료.toLocaleString()}}
                        <input type="number" class="form-control w-100 text-center" placeholder="수수료율" v-model="item.수수료율" @change="changeCost(item)">
                    </template>
                    <template v-slot:월매출="{item}">
                        {{item.월매출.toLocaleString()}}
                    </template>
					<template v-slot:광고ROI="{item}">
                        {{item.광고ROI ? Math.round(item.광고ROI*10000)/100 + '%' : ''}} / {{item.광고ROI ? Math.round(item.광고ROI*item.키워드CPC/100*10000)/100 + '%' : ''}}
                    </template>
					<template v-slot:메모="{item}">
						<input type="text" class="form-control w-100 text-center" placeholder="" v-model="item.메모">
					</template>
                </CDataTable>
				<div class="d-flex justify-content-between">
					<div>
						<button type="button" class="btn btn-success mr-2 mb-4" @click="saveStatus">현재상태저장</button>
						<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveitem">상품찜</button>
						<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
						<button type="button" class="btn btn-success mr-2 mb-4" @click="downloadsales">다운로드</button>
						<button type="button" class="btn btn-danger mr-2 mb-4" v-b-toggle.my-collapse>일괄변경</button>
					</div>
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="addCollect">상품 더 불러오기</button>
				</div>
				<b-collapse id="my-collapse">
					<b-card title="">
						<div class="form-group">
							<input type="text" class="form-control" placeholder="구매가(CNY)" v-model="일괄구매가">
						</div>
						<div class="form-group">
							<input type="number" class="form-control" placeholder="매입가율" v-model="일괄매입가율">
						</div>
						<div class="form-group">
							<input type="text" class="form-control" placeholder="판매가" v-model="일괄판매가">
						</div>
						<button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
					</b-card>
				</b-collapse>


			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import { core } from '../../../config/pluginInit';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
    components:{
        CDataTable
    },
	data(){
		return{
			일괄구매가:'',
			일괄판매가:'',
			일괄매입가율:'',
            headers: [
				{text: '소싱방법\nBadge', value: '로켓뱃지', align: 'center', width: 100, isSlot: true},
				{text: '수집시간', value: '수집시간', align: 'center', width: 100, isSlot: false},
				{text: '수집자', value: 'collector', align: 'center', width: 100, isSlot: false},
                {text: '타겟키워드', value: '타겟키워드', align: 'center', width: 120, isSlot: false},
                {text: '상품정보', value: '판매상품명', align: 'center', width: 400, isSlot: true},
				{text: '결제전환율', value: '결제전환율', align: 'center', width: 100, isSlot: true},
				{text: '월조회수', value: '조회수', align: 'center', width: 100, isSlot: true},
                {text: '구매정보', value: '구매가', align: 'center', width: 250, isSlot: true},
                {text: '매입가\n(원)', value: '매입가', align: 'center', width: 80, isSlot: false},
                {text: '예상월수익(원)', value: '월수익', align: 'center', width: 120, isSlot: false},
                {text: '예상마진(원)\n마진율(%)\nROI(%)', value: '마진', align: 'center', width: 120, isSlot: true},
                {text: '판매가(원)', value: '판매가', align: 'center', width: 150, isSlot: true},
                {text: '수수료(원)\n수수료율(%)', value: '수수료율', align: 'center', width: 100, isSlot: true},
                {text: '월판매량\n(개)', value: '월판매량', align: 'center', width: 80, isSlot: false},
                {text: '월매출(원)', value: '월매출', align: 'center', width: 120, isSlot: true},
                {text: '리뷰수', value: '리뷰수', align: 'center', width: 80, isSlot: false},
                {text: '리뷰평점', value: '리뷰평점', align: 'center', width: 80, isSlot: false},
                {text: '연관키워드', value: '연관키워드', align: 'center', width: 120, isSlot: false},
				{text: '광고ROI\n최소광고ROI', value: '광고ROI', align: 'center', width: 120, isSlot: true},
				{text: '키워드\nCPC', value: '키워드CPC', align: 'center', width: 80, isSlot: false},
				{text: '메모', value: '메모', align: 'center', width: 120, isSlot: true},
            ],
            tableSelectedItem: [],
			lineselect:false,
			salesRate:5,
			purchaseRatio:350,
			charge: 35,
			auth:'',


			myData : [1000,500,250,125,62,31,15,7,3,1,1,1,1,1,0],
			salesarr:[],
			importarr:[],
			stockarr:[],
			data:[],
			checkalllists:false,

			targetKeyword:'',
			related:false,
			keywords:[], //[{keyword:"keyword",token:"1"},{keyword:"keyword2",token:"1"},...]

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
            page: 1,
			// increview:0,
			// decreview:0,

			loadingtext:'',
			loadingrate:'',


			title:"트렌드팔로워 소싱 Ver.2",
			desc1:"소싱 제품 수집하기 기능을 사용하기 전에 쿠팡윙을 다른 탭에 먼저 로그인해주세요.",
			desc2:"키워드를 입력하면 현재 쿠팡 무료프로모션 상품으로 판매되는 제품의 판매정보를 불러옵니다.",
			desc3:"",
			link:{ name: 'people.addusers'},
			linktext:"판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			maxrows : 200,
			minuslimit : -2,
			applyminuslimit : false,
			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			],

		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
    mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'login'){
				alert('쿠팡wing에 로그인 해주세요')
				this.showspinner = false;
				this.showlist = false;
				return
			}
            if(event.data.res && event.data.msg === 'NomadTF2'){
                if(event.data.res === 'fail'){
					this.loadingtext = '';
					alert('수집실패')
					console.log(event.data)
					this.showspinner = false;
					this.showlist = true;
				} else {
					if(event.data.products.length === 0){
						alert('수집된 데이터가 없습니다.')
						return
					}
					var products = event.data.products;
					var keywordCPC = event.data.keywordCPC;
					var arr = [];
					for(var e of products){
						if(this.keywords.find(f => f.keyword === e.keyword)){
							this.keywords.find(f => f.keyword === e.keyword).token = e.token;
						} else {
							this.keywords.push({
								keyword:e.keyword,
								token:e.token
							})
						}
						var 키워드CPC = keywordCPC[e.keyword].suggested;
						for(var f of e.promotionData){
							if(!this.saleslists.find(ele => ele.productId == f.id)){
								if(arr.length == 0 || !arr.find(ele => ele.productId == f.id)){
									if(f.promisedDeliveryDates.length > 0){
										if(f.promisedDeliveryDates[0].carrier == 'COUPANG' && f.promisedDeliveryDates[0].deliverType == 'NORMAL'){
											var badge = 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png';
										}
										if(f.promisedDeliveryDates[0].deliverType == 'COUPANG_OVERSEAS'){
											badge = "https://image6.coupangcdn.com/image/mypromotion/icons/jikgu.png";
										}
										if(f.promisedDeliveryDates[0].carrier !== 'COUPANG' && f.promisedDeliveryDates[0].deliverType !== 'COUPANG_OVERSEAS'){
											badge = "";
										}
									} else {
										badge = "";
									}
									var 수집시간 = e.time ? this.formatTimestamp(e.time) : this.formatTimestamp(Date.now());
									var obj = {
										time : Date.now(),
										수집시간 : 수집시간,
										collector : this.$store.state.user.name,
										checked : false,
										isLoading : false,
										썸네일 : 'https://image1.coupangcdn.com/image/' + f.imagePath,
										판매상품명 : f.title,
										productId : f.id,
										vendorItemId : f.vendorItemId,
										판매가 : f.priceInfo.discountedPrice,
										타겟키워드 : e.keyword,
										연관키워드 : '',
										리뷰수 : f.ratingInfo.ratingCount,
										리뷰평점 : f.ratingInfo.ratingAverage,
										type:'Trend Follower',
										구매가 : Math.round(f.priceInfo.discountedPrice*0.00088*10)/10,
										월수익 : 0,
										마진 : 0,
										마진율 : 0,
										매입가 : 0,
										매입가율 : this.purchaseRatio,
										수수료율 : this.charge,
										수수료 : parseInt(this.charge*f.priceInfo.discountedPrice*0.01),
										월판매량 : parseInt(f.properties.product_monthly_unit_sold),
										월매출 : parseInt(f.properties.product_monthly_unit_sold)*parseInt(f.priceInfo.discountedPrice),
										상품매칭 : [],
										판매링크 : "https://www.coupang.com/vp/products/" + f.id + "?vendorItemId=" + f.vendorItemId,
										currentindex : 0,
										로켓뱃지 : badge,
										조회수 : f.조회수,
										결제전환율 : (parseInt(f.properties.product_monthly_unit_sold)/30)/(f.조회수/28),
										키워드CPC : 키워드CPC,
										info:f,
										companyid:this.$store.state.user.companyid,
										메모:"",
									}
									this.changeCost(obj)
									arr.push(this.deepClone(obj))
								}

							}
						}
					}

					if(this.saleslists.length > 0 && arr.length > 0){
						arr = arr.filter(f => !this.saleslists.find(ele => ele.vendorItemId == f.vendorItemId));
					}

					if(arr.length + this.saleslists.length > 1000){
						alert('저장 가능한 갯수를 초과하였습니다 - 저장 가능 갯수 1000개')
						return
					}

					if(arr.length == 0){
						this.showspinner = false;
						this.showlist = true;
						return
					}
					axios.post('/api/nomad/saveNomadTF2',arr)
					.then(result => {
						if(result.data.res == 'OK'){
							var data = this.$store.state.TF2.concat(arr);
							console.log(this.$store.state.TF2.length, arr.length, data.length)
							this.$store.commit('updateTF2',data);
							this.search()
						} else {
							alert(result.data.alert)
							console.log(result)
						}
					})
				}
            }
            if (event.data.res && event.data.res === 'growthdbratio'){
                if(event.data.ratio == 0){
                    this.loadingtext = event.data.text
                } else {
                    this.loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%'
                }
            }
			if(event.data.res && event.data.res === 'keywordanalysisstatusratio'){
                console.log(event.data.status);
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
            }
			if(event.data.res && event.data.res === 'relatedkeywordsTF2'){
                console.log(event.data.relatedkeywords)
				arr = event.data.relatedkeywords;
				var ar = [];
				if(this.related){
					ar = [this.targetKeyword]
				}
				// console.log(arr)
				arr.forEach(ele => {
					if(!ar.includes(ele)){
						ar.push(ele)
					}
				})

				var keywords = [];
				for(var keyword of ar){
					if(!this.keywords.find(e => e.keyword === keyword)){
						keywords.push({
							keyword:keyword,
							token:'',
						})
					}
				}

				this.importresults(keywords);
            }
		}
		window.addEventListener('message', this.messageEventHandler);

        this.$nextTick(function () {
            this.search()
        })
    },
	methods: {
		formatTimestamp(timestamp) {
			// Date 객체 생성
			const date = new Date(timestamp);

			// 년도, 월, 일, 시간, 분, 초를 각각 추출
			const year = date.getFullYear().toString().substr(-2); // 끝에서 두 자리만 추출
			const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
			const day = date.getDate();
			const hours = date.getHours();
			const minutes = date.getMinutes();
			const seconds = date.getSeconds();

			// 각 항목을 두 자리 숫자 형태로 포매팅
			const formattedMonth = month < 10 ? `0${month}` : month;
			const formattedDay = day < 10 ? `0${day}` : day;
			const formattedHours = hours < 10 ? `0${hours}` : hours;
			const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
			const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

			// 원하는 형식으로 조합하여 반환
			return `${year}.${formattedMonth}.${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
		},
		editlists(){
			if(this.tableSelectedItem.length == 0){
				alert('변경할 내역을 선택해 주세요.')
				return
			}
			for(var e of this.tableSelectedItem){
				if(this.일괄구매가){
					e.구매가 = this.일괄구매가;
				}
				if(this.일괄매입가율){
					e.매입가율 = this.일괄매입가율
				}
				if(this.일괄판매가){
					e.판매가 = this.일괄판매가
				}
				this.changeCost(e)
			}
			this.일괄구매가 = '';
			this.일괄매입가율 = '';
			this.일괄판매가 = '';
		},
		addCollect(){
			if(this.keywords.length == 0){
				alert('초기 수집을 먼저 진행해 주세요')
				return
			}
			if(this.saleslists.length > 500){
				alert('분석 수량이 초과되었습니다. 기존 분석 데이터를 삭제 후 다시 시도해 주세요.')
				return
			}
			console.log(this.keywords)
			this.importresults(this.keywords);
		},
		selectline(){
			var pageInfo = core.checkpage();
			for(var i=0;i<this.saleslists.length;i++){
				if(i >= pageInfo.start && i < pageInfo.end){
					this.saleslists[i].checked = true
				}
			}
		},
		loadImage(src) {
			return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;

			img.onload = () => {
				resolve();
			};

			img.onerror = (error) => {
				reject(error);
			};
			});
		},
		showts(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendSetter');
                this.showlist = true;
            }, 1);
        },
		showtf(){
            this.showlist=false;
            setTimeout(() => {
                this.saleslists = this.saleslistsinit.filter(e => e.type == 'trendFollower');
                this.showlist = true;
            }, 1);
        },
		async previmg(saleslist,bool){
			saleslist.isLoading = true; // 이미지 로딩 중으로 설정
			// if(bool){
			// 	this.showlist = false;
			// }

			if(saleslist.currentindex == 0){
				saleslist.currentindex = saleslist.상품매칭.length - 1;
				return
			}
			saleslist.currentindex--

			// 이미지 변경 로직
			const newImageUrl = saleslist.상품매칭[saleslist.currentindex].pic_url;
			// 이미지를 미리 로드합니다.
			await this.loadImage(newImageUrl);
			// 이미지 변경 후
			saleslist.isLoading = false; // 로딩 완료로 설정
			if(bool){
				this.applyCurrentObj(saleslist);
			// 	setTimeout(()=>{
			// 		this.showlist = true;
			// 	},1)
			}
		},
        changePage(eventValue) {
            this.page = eventValue
        },
		async nextimg(saleslist,bool){
			saleslist.isLoading = true; // 이미지 로딩 중으로 설정
			// if(bool){
			// 	this.showlist = false;
			// }
			if(saleslist.currentindex == saleslist.상품매칭.length - 1){
				saleslist.currentindex = 0;
				return
			}
			saleslist.currentindex++

			// 이미지 변경 로직
			const newImageUrl = saleslist.상품매칭[saleslist.currentindex].pic_url;
			// 이미지를 미리 로드합니다.
			await this.loadImage(newImageUrl);
			// 이미지 변경 후
			saleslist.isLoading = false; // 로딩 완료로 설정

			if(bool){
				this.applyCurrentObj(saleslist);
				// setTimeout(()=>{
				// 	this.showlist = true;
				// },1)
			}
		},
		applyCurrentObj(saleslist){
			var e = saleslist;
			saleslist.구매가 = e.상품매칭[e.currentindex].price;
			saleslist.월수익 = (e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율))*parseInt(e.월매출/e.판매가)
			saleslist.마진 = e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율)
			saleslist.마진율 = parseInt((e.판매가*0.9 - parseInt(saleslist.구매가*saleslist.매입가율))/e.판매가*100)
			saleslist.매입가 = parseInt(saleslist.구매가*saleslist.매입가율);
			if(saleslist.키워드CPC){
				saleslist.광고ROI = saleslist.마진*saleslist.결제전환율/saleslist.키워드CPC;
			}
		},
		changeCost(saleslist){
			saleslist.매입가 = parseInt(saleslist.구매가*saleslist.매입가율);
			saleslist.수수료 = parseInt(saleslist.판매가*saleslist.수수료율*0.01);
			saleslist.부가세 = saleslist.판매가/11 - saleslist.구매가*saleslist.매입가율/11 - saleslist.수수료/11
			saleslist.마진 = parseInt(saleslist.판매가 - saleslist.매입가 - saleslist.수수료 - saleslist.부가세);
			saleslist.ROI = (saleslist.마진/saleslist.매입가)*100
			saleslist.마진율 = (saleslist.마진/saleslist.판매가)*100;
			saleslist.월수익 = (saleslist.마진*parseInt(saleslist.월매출/saleslist.판매가));
			if(saleslist.키워드CPC){
				saleslist.광고ROI = saleslist.마진*saleslist.결제전환율/saleslist.키워드CPC;
			}
		},
		openWebsite(url) {
			window.open(url, "_blank");
		},
		linktopurchase(purchase){
			window.open(purchase.detail_url,"_blank");
		},
		applycorrect(){
			if(this.data.length > 0){
				this.showspinner = true;
				this.showlist = false;
				this.searchresult()
			} else {
				alert('추가된 소싱상품이 없습니다.')
				return
			}
		},
		checkalllist(){
			// var pageInfo = core.checkpage();
			// console.log(pageInfo)
// console.log('a')
			if(this.checkalllists){
				this.saleslists.forEach(e => {
					e.checked = true
				})
			} else {
				this.saleslists.forEach(e => {
					e.checked = false
				})
			}
		},
		saveitem(){
			if(this.tableSelectedItem.length == 0){
				alert('찜할 상품을 선택해 주세요')
			} else {
				if(confirm(this.tableSelectedItem.length + '개의 상품을 찜하시겠습니까?')){
					for(var ele of this.tableSelectedItem){
						ele.collector = ele.collector + "/" + this.$store.state.user.name
					}
					this.$store.commit('updateSaveItemStatus',true)
					axios.post('/api/nomad/addsaveitemMTF2',this.tableSelectedItem)
					.then(result => {
						if(result.data.res == 'OK'){
							alert(result.data.cnt + '개 상품 찜 완료')
							console.log(result)
							var arr = [];
							for(var e of this.saleslists){
								if(!this.tableSelectedItem.find(f => f._id == e._id)){
									arr.push(this.deepClone(e))
								}
							}
							this.$store.commit('updateTF2',arr)
							this.search();
						} else {
							if(result.data.res == 'Fail'){
								alert('장바구니에는 100개 이상 찜할 수 없습니다. (남은수량 : ' + result.data.remainQty + ')')
								console.log(result)
							} else {
								alert('찜 실패')
								console.log(result)
							}
						}
					})
				}
			}
		},
		saveStatus(){
			if(this.tableSelectedItem.length == 0){
				alert('저장할 항목을 선택해 주세요')
				return
			}
			if(confirm('현재 상태를 저장하시겠습니까?')){
				this.$store.commit('updateTF2',this.saleslists)
				axios.post('/api/nomad/saveNomadTF2',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						alert('저장완료')
						console.log(result)
					} else {
						alert(result.data.alert)
						console.log(result)
					}
				})
			}
		},
		deleteitem(){
			if(this.tableSelectedItem.length == 0){
				alert('삭제할 상품을 선택해 주세요')
			} else {
				if(this.tableSelectedItem.length == this.saleslists.length){
					if(!confirm('전체 내역을 삭제하시겠습니까?')){
						return
					}
				} else {
					if(!confirm(this.tableSelectedItem.length + '개의 상품을 삭제하시겠습니까?')){
						return
					}
				}
				this.showlist = false;
				this.showspinner = true;

				axios.post('/api/nomad/deleteNomadTF2',this.tableSelectedItem.filter(e => e._id))
				.then(result => {
					if(result.data.res == 'OK'){
						var arr = [];
						for(var e of this.saleslists){
							if(!this.tableSelectedItem.find(f => f._id == e._id)){
								arr.push(this.deepClone(e))
							}
						}
						this.$store.commit('updateTF2',arr)
						this.search();
					} else {
						alert('삭제실패')
						console.log(result)
					}
				})
			}
		},
		collecttr(){
			window.postMessage({greeting: "collecttracking", companyid:this.$store.state.user.companyid},"*",)
			this.showspinner = true;
			this.showlist = false;
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		modalIDca(index) {
            // console.log(index);
            return 'modalca' + index
        },
		salesdownload(){
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			const workBook = XLSX.utils.book_new()
			var obj = {};
			var arr = [];
			this.saleslists.forEach(e => {
				arr = [];
				e.판매세부내역.forEach(ele => {
					obj = {
						상품명 : e.판매상품명,
						Date : ele.date,
						재고 : ele.재고,
						판매 : ele.판매,
						판매가 : ele.판매가,
						판매매출 : ele.판매매출,
						리뷰수 : ele.리뷰수,
						리뷰평점 : ele.리뷰평점
					}
					arr.push(obj)
				})
				var workSheet = XLSX.utils.json_to_sheet(arr)
				XLSX.utils.book_append_sheet(workBook, workSheet, e.vendorItemId.toString())
			})


			XLSX.writeFile(workBook, '일자별판매현황.xlsx')
		},
		collectenter(event){
			if(event.keyCode === 13){
				this.collect();
			}
		},
		collect(){
			if(!this.targetKeyword){
				alert('분석할 키워드를 입력해 주세요')
				return
			}
			if(this.saleslists.length > 500){
				alert('분석 수량이 초과되었습니다. 기존 데이터를 삭제 후 다시 시도해 주세요.')
				return
			}
			if(!this.related){
				this.importresults([{keyword:this.targetKeyword,token:''}])
				return
			}
			window.postMessage({greeting: "getrelatedkeywordsTF2",data:this.targetKeyword},"*",)
			this.loadingtext = '연관검색어 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
		},
		async importresults(arr){
			if(this.saleslists.length >= 1000){
				alert('수집 수량이 한도(1000개)수량을 초과합니다. 상품을 정리 후에 검색해 주세요')
				return
			}
			console.log(arr)
			// await this.sleep(1000);
			this.loadingtext = '키워드 별 쿠팡 판매량 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
			window.postMessage({greeting: "NomadTF2",data:arr,suppliercode:this.$store.state.user.suppliercode},"*",)
		},
		search(){
			this.showspinner = true;
			this.loadingtext = '상품 불러오는 중...'
			this.showlist = false;
			this.saleslists = [];
			this.saleslistsinit = [];
			if(this.$store.state.TF2.length > 0){
				console.log(this.$store.state.TF2.length)
				this.$store.state.TF2.forEach(e => {
					this.saleslists.push(this.deepClone(e))
					this.saleslistsinit.push(this.deepClone(e))
				})
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
				return
			}
			axios.post('/api/nomad/getNomadTF2',{companyid:this.$store.state.user.companyid})
			.then(result => {
                if(result.data.length > 0){
					console.log(result.data)
					this.searchresult(result.data)
                } else {
                    alert('추가된 모니터링 상품이 없습니다.')
					this.showspinner = false;
                    return
                }
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
        searchresult(data){
			this.saleslists = [];
			this.saleslistsinit = [];
			this.$store.commit('updateTF2',data);
			data.forEach(e => {
				this.saleslists.push(this.deepClone(e))
				this.saleslistsinit.push(this.deepClone(e))
			})
            this.saleslists = data;
            this.saleslistsinit = [];

			setTimeout(() => {
				this.showlist = true;
				this.showspinner = false;
			}, 10);
			console.log(this.saleslists)

        },
		timestamptodate(timestamp){
			var d = new Date(timestamp + (1000*60*60*9));
			return d.toISOString().replace('T',' ').slice(0,19)
		},
		searchresult2(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			var productdb = this.$store.state.productdb.filter(e => !e.등록이미지?.includes('vendor_inventory'));
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})



				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){

								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
										console.log(e)
										console.log(sales[j].판매세부내역[n])
									}
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						pred = d;
						obj.판매세부내역.push(d)

						obj.재고 = d.재고;
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}


				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}
			console.log(this.salesarr)
		},

		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			if(this.tableSelectedItem == 0){
                this.tableSelectedItem = this.saleslists
			}
			var arr = [];
			var obj = {};
            this.tableSelectedItem.forEach(e => {
				if(e.로켓뱃지 == 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'){
					var 로켓뱃지 = '판매자로켓'
				}
				if(e.로켓뱃지 == 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'){
					로켓뱃지 = '로켓배송'
				}
				if(e.로켓뱃지 == ''){
					로켓뱃지 = '마켓플레이스'
				}
				obj = {
					썸네일 : e.썸네일,
					판매상품명 : e.판매상품명,
					상품번호 : e.productId,
					판매가 : e.판매가,
					타겟키워드 : e.타겟키워드,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					소싱타입 : e.type,
					월수익 : e.월수익,
					마진 : e.마진,
					마진율 : e.마진율,
					ROI : e.마진/e.매입가,
					매입가 : e.매입가,
					수수료 : e.수수료,
					월판매량 : e.월판매량,
					월매출 : e.월매출,
					로켓뱃지 : 로켓뱃지,
					판매상품링크 : e.판매링크,
					결제전환율 : e.결제전환율,
					월조회수 : e.조회수,
					광고ROI : e.광고ROI ? Math.round(e.광고ROI*10000)/100 : "",
					최소광고ROI : e.광고ROI ? Math.round(e.광고ROI*e.키워드CPC/100*10000)/100 : "",
					타겟키워드CPC : e.키워드CPC,
					구매링크:'',
					수집시간 : e.수집시간,
					수집자 : e.collector,
					메모:e.메모,
					'1.타겟키워드/상품간 매칭성(TS)':'',
					'2.지재권 문제':'',
					'3.마진율/ROI 만족여부':'',
					'4.타겟키워드 상품대비 차별성':'',
					'5.쿠팡 연관검색어에 동일상품 여부':'',
					'6.KC인증/전파인증/식검':'',
					'7.최근 1개월 가리뷰(TF)':'',
					'8.리뷰상 상품 문제점':'',
					'9.타겟키워드 재설정':'',
				}
				arr.push(obj)
			})


			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '소싱리스트')
			XLSX.writeFile(workBook, '소싱리스트' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style scoped>
.my-class .dropdown-menu {
	max-height: 300px;
	width:800px;
	overflow-y: auto;
}
.image-container {
  position: relative;
  display: inline-block;
}

.index-label {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8); /* 배경 색상 */
  padding: 4px 8px; /* 여백 조절 */
  border-radius: 4px; /* 모서리 둥글게 만들기 */
  font-size: 14px;
}
</style>
